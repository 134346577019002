import React, { useContext, useCallback } from "react";
import "./Parcours.scss";
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  useNavigate
} from "react-router-dom";
import Conf from 'Conf';

function Parcours({mini=false,exclude=[],disableGutters=false}) {
  const navigate=useNavigate();
  const { getDoc } = useContext(ModelContext);
  const parcours=getDoc(['parcours']).filter(o=>o.status==="online");
  const tile=useCallback((parcour)=>{
    return <Grid key={parcour.id} item xs={12} sm={mini ? 4 : 6}>
      <Card onClick={()=>navigate('/parcours/'+parcour.id)} className={'parcours-tile'+(mini ? ' mini' : '')}>
        {parcour.media && parcour.media[0] && <img className="img-responsive" src={Conf.filesUrl+parcour.media[0].url+'-600'} alt=""/>}
        <CardContent className="sort-handle parcour-content">
          <Typography variant="h3">
            {parcour.titre || 'Sans nom'}
          </Typography>
        </CardContent>
      </Card>
    </Grid>;
  },[navigate,mini]);

  return <Container maxWidth="lg" className="parcours" disableGutters={disableGutters}>
    <Grid container spacing={2}>
    {parcours && parcours.filter(o=>exclude.findIndex(id=>id===o.id)===-1).map((o)=>tile(o))}
    </Grid>
    <div className="spacer"></div> 
  </Container>
}

export default Parcours;
