import React, { useEffect, useState, useCallback } from "react";
import "./Player.scss";
import Typography from '@mui/material/Typography';
import Conf from 'Conf';
import Audio from 'shared/Blocs/Audio';
import Video from 'shared/Blocs/Video';
import Texte from 'shared/Blocs/Texte';
import Md from 'shared/Md';
import Container from '@mui/material/Container';

function Player({parcour, footer=null}) {
  const [ playerSize,setPlayerSize ] = useState('xs');
  const [ currentPlayer,setCurrentPlayer ] = useState([]);
  const [ container,setContainer ] = useState(null);
  const containerSetter=useCallback(element=>setContainer(element),[setContainer]);
  useEffect(()=>{
    if (container) {
      const handleResize=()=>{
        const { width } = container.getBoundingClientRect();
        let size="xs";
        if (width>576) size="sm";
        if (width>768) size="md";
        if (width>992) size="lg";
        if (width>1200) size="xl";
        if (width>1600) size="xxl";
        setPlayerSize(size);
        ["xs","sm","md","lg","xl","xxl"].forEach((s) => {
          if (s!==size) container.classList.remove(s);
        });
        container.classList.add(size);
      }
      window.addEventListener('resize',handleResize);
      handleResize();
      return ()=>window.removeEventListener('resize',handleResize);
    }
  },[container])
  const handlePlay=useCallback((player)=>{
    player && setCurrentPlayer(player);
    console.log(player,'play')
  },[setCurrentPlayer]);
  return <div className='player-container' ref={containerSetter}>
  <Container maxWidth="lg" className="parcour" disableGutters>
    <div className='player-header'>
      {parcour && <>
        {parcour.media && parcour.media[0] && <img className="img-responsive" src={Conf.filesUrl+parcour.media[0].url+'-1000'} alt=""/>}
      </>}
      <Typography variant="h1">
        {parcour.titre}
      </Typography>
    </div>
    <div className="spacer"></div>
    <div className='player-body'>
      {parcour && <>
        <Md text={parcour.descriptif} />
      </>}
      <div className="spacer"></div>
      {parcour && parcour.blocs.map((bloc,i)=><div key={bloc.id} className="bloc">
        {bloc.type==='audio' && <Audio bloc={bloc} container={container} params={{playerSize}} onPlay={handlePlay} currentPlayer={currentPlayer || null}/>}
        {bloc.type==='video' && <Video bloc={bloc} container={container} params={{playerSize}} onPlay={handlePlay} currentPlayer={currentPlayer || null}/>}
        {bloc.type==='texte' && <Texte bloc={bloc} params={{playerSize}}/>}
      </div>)}
    </div>
  </Container>
  <div className="player-footer">
    {footer}
  </div>
 </div>;
}

export default Player;
