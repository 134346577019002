import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from "react";
import "./Blocs.scss";
import { WaveSurfer, WaveForm } from 'wavesurfer-react';
import Conf from 'Conf';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Md from 'shared/Md';
import Fab from '@mui/material/Fab';

function Audio({bloc,container,params,onPlay,currentPlayer}) {
  const cache=useRef(null);
  const first=useRef(true);
  const [ loaded, setLoaded ] = useState(0);
  const [ playing, setPlaying ] = useState(false);
  const [ ready,setReady ] = useState(false);
  const [ active,setActive ] = useState(false);
  const player=useRef(null);
  const topMark=useRef(null);
  const bottomMark=useRef(null);
  const [ stickyState, setStickyState ] = useState({
    top:false,
    bottom:false,
  });
  useEffect(()=>{
    const { top,bottom } = stickyState;
    if (top && bottom) {
      if (!active) setActive(true);
      if (first.current) {
        wavesurferRef.current && wavesurferRef.current.play();
        first.current=false;
      }
     } else {
      if (active) setActive(false);
    }
  },[stickyState,active,setActive]);
  useEffect(()=>{
    if(currentPlayer && cache.current!==currentPlayer.id) {
      console.log('current changed',currentPlayer,player.current.id);
      cache.current=currentPlayer.id;
      if (currentPlayer.id!==player.current.id && !wavesurferRef.current.paused) {
        wavesurferRef.current && wavesurferRef.current.pause();
      }
    }
  },[currentPlayer]);
  useLayoutEffect(()=>{
    if (container && player.current && topMark.current && bottomMark.current) {
      console.log('init observer');
      const observerTop = new IntersectionObserver(([node], observer)=>{
        const targetInfo = node.boundingClientRect;
        const rootBoundsInfo = node.rootBounds;
        if (targetInfo.bottom < rootBoundsInfo.top) {
          setStickyState(state=>{return{...state,top:true}});
        } else {
          setStickyState(state=>{return{...state,top:false}});
        }
      }, {
        root:container,
        rootMargin: '0px',
        threshold:1,
      });
      observerTop.observe(topMark.current);
      const observerBottom = new IntersectionObserver(([node], observer)=>{
        const targetInfo = node.boundingClientRect;
        const rootBoundsInfo = node.rootBounds;
        if (targetInfo.top < rootBoundsInfo.top) {
          setStickyState(state=>{return{...state,bottom:false}});
        } else {
          setStickyState(state=>{return{...state,bottom:true}});
        }
      }, {
        root:container,
        rootMargin: '0px',
        threshold:1,
      });
      observerBottom.observe(bottomMark.current);
      return ()=>{
        observerTop.disconnect();
        observerBottom.disconnect();
      }
    }
  },[container]);
  const wavesurferRef = useRef();
  const handleWSMount = useCallback((waveSurfer) => {
   wavesurferRef.current = waveSurfer;
   if (wavesurferRef.current) {
     wavesurferRef.current.on("loading", (p) => {
       setLoaded(p);
     });
     wavesurferRef.current.on("ready", () => {
      console.log("WaveSurfer is ready");
      setReady(true);
    });
    wavesurferRef.current.on("play", () => {
       setPlaying(true);
       onPlay(player.current);
     });
     wavesurferRef.current.on("pause", () => {
       setPlaying(false);
     });
   }
 },[setReady,setPlaying,onPlay]);
  useEffect(()=>{
    if (wavesurferRef.current && bloc && bloc.media && bloc.media[0] && bloc.media[0].type.startsWith('audio/')) {
      setReady(false);
      wavesurferRef.current.load(Conf.filesUrl+bloc.media[0].url+"-44100.mp3?2");
    }
  },[bloc]);
  const togglePlay=useCallback(() => {
    wavesurferRef.current.playPause();
  }, []);
  return <div className='bloc bloc-audio'>
    <div ref={topMark}></div>
    <div className={'player'+(ready ? ' ready' : ' loading')+(active ? ' active' : '')} ref={player} id={'bloc-'+bloc.id}>
      <WaveSurfer key={bloc.id} onMount={handleWSMount} responsive={true}>
        <WaveForm id={"waveform-"+bloc.id} cursorColor="transparent">
        </WaveForm>
        <div className="controls">
          <Fab color="primary" onClick={togglePlay} size="small">
            {playing ? <PauseIcon/> : <PlayArrowIcon/>}
          </Fab>
        </div>
      </WaveSurfer>
      <div className="loader">
        {loaded}%
      </div>
      {bloc.metadata && <div className="metadata">
        <Md text={bloc.metadata} />
      </div>}
    </div>
    <div className="spacer"></div>
    {bloc.titre && <div className="titre">
      {bloc.titre}
    </div>}
    {bloc.texte && <div className="texte">
      <Md text={bloc.texte} />
    </div>}
    <div ref={bottomMark} className="bottom-mark"></div>
    <div className="spacer"></div>
  </div>;
}

export default Audio;
