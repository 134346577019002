import React, { useContext } from "react";
import "./Home.scss";
import Parcours from 'components/Parcours';
import Footer from 'components/Footer';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ModelContext } from "providers/ModelProvider";
import Md from 'shared/Md';
import Conf from 'Conf';
function Home() {
  const { getDoc } = useContext(ModelContext);
  const settings=getDoc(['settings']);
  const titre=(settings.find((o)=>o.id==='titre') || {value:''}).value;
  const soustitre=(settings.find((o)=>o.id==='soustitre') || {value:''}).value;
  const edito=(settings.find((o)=>o.id==='edito') || {value:''}).value;
  return <>
    <Container maxWidth="lg" className="home">
    <Typography variant="h1">
        {titre}
      </Typography>
      <Typography variant="h3">
        {soustitre}
      </Typography>
      {edito && <div className="edito">
        <Md text={edito.replace(/\[image:(.*)\]/gm,'![image]('+Conf.filesUrl+'$1-600)')}/>
      </div>}
    </Container>
    <Parcours/>
    <div className="spacer"></div> 
    <Footer/>
  </>;
}

export default Home;
