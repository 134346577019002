import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'shared/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ModelProvider from "providers/ModelProvider";
import SettingsProvider from "providers/SettingsProvider";
import { BrowserRouter } from "react-router-dom";
import Utils from 'utils/Utils';
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ModelProvider>
        <SettingsProvider>
          <ThemeProvider theme={Utils.theme}>
            <App />
          </ThemeProvider>
        </SettingsProvider>
      </ModelProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
