import React, { useState, createContext, useEffect, useCallback } from "react";
import Conf from 'Conf';
import * as _ from 'lodash';
import axios from 'axios';

const historyKey='collectages-history';
let localHistory=null;
if ('localStorage' in window) {
  localHistory=localStorage.getItem(historyKey);
}
const parseCustomPath=(customPath,doc,createItem=false)=>{
  //console.log(customPath,doc);
  let p=[...customPath];
  let path=[];
  while (p.length>0) {
    let current=p[0];
    let el=path.length>0 ? _.get(doc,path) : doc;
    if (el) {
      if (Array.isArray(current)) {
        let id=current[0];
        let idKey=current[1] || 'id';
        if (Array.isArray(el)) {
          let idx=el.findIndex((o)=>o[idKey]===id);
          if (idx!==-1) path.push(idx);
          else {
            if (createItem) {
              path.push(el.length);
              el.push({[idKey]:id});
            } else {
              console.log('not found');
              return false;
            }
          }
        } else {
          console.log('not found');
        }
      } else {
        path.push(current)
      }
    } else {
      console.log('not found');
    }
    p.splice(0,1);
  }
  //console.log('parseCustomPath',customPath,path);
  return path;
};
const get=(o,path)=>{
  return _.get(o,parseCustomPath(path,o));
}

export const ModelContext = createContext({});

let data={};
if ('localStorage' in window) {
  const dataLocal=JSON.parse(localStorage.getItem('collectages-data'));
  if (dataLocal) data=dataLocal;
}

const ModelProvider = ({children})=>{
  const [ modele,setModele ] = useState(data);
  const [ history,setHistory ] = useState(localHistory ? JSON.parse(localHistory) : {});
  useEffect(()=>{
    if ('localStorage' in window) localStorage.setItem('collectages-history',JSON.stringify(history));
  },[history]);
  useEffect(()=>{
    const getData=()=>{
      axios.get(Conf.filesUrl+'public.json?'+Date.now())
      .then(function (response) {
        setModele(response.data);
        if ('localStorage' in window) localStorage.setItem('collectages-data',JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    getData();
  }
  ,[setModele]);
  const getCollection=useCallback((type)=>{
    return modele.filter((o)=>o.doc.type===type).map((o)=>o.doc).sort((a,b)=> a.sort-b.sort);
  },[modele]);
  const getDoc=useCallback((path)=>{
    return get(modele,path);
  },[modele]);
  useEffect(()=>{
    const handleKeyPress=(e)=>{
      if (e.key==='h') setHistory({});
    }
    window.addEventListener('keypress',handleKeyPress);
    return ()=>{
      window.removeEventListener('keypress',handleKeyPress);
    }
  })
  return (
        <ModelContext.Provider value={{setHistory,history,modele,getCollection,get,getDoc}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
