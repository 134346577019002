import React, { useContext } from "react";
import "./Footer.scss";
import Container from '@mui/material/Container';
import { ModelContext } from "providers/ModelProvider";
import Grid from '@mui/material/Grid';
import Md from 'shared/Md';
import Conf from 'Conf';

function Footer() {
  const { getDoc } = useContext(ModelContext);
  const settings=getDoc(['settings']);
  const footer=(settings.find((o)=>o.id==='footer') || {value:''}).value;
  console.log(settings);
  return <div className="footer">
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <a href="https://www.pirena-immateria.org" target="_blank" rel="noopener noreferrer"><strong>Pirèna Immatèria</strong></a>
          <a href="https://www.facebook.com/pirenaimmateria/" target="_blank" rel="noopener noreferrer">
            <div className="fb"></div>
          </a>
        </Grid>
        <Grid item xs={12} sm={8}>
          {footer && <Md text={footer.replace(/\[image:(.*)\]/gm,'![image]('+Conf.filesUrl+'$1-600)')}/>}
        </Grid>
      </Grid>
    </Container>
  </div>;
}

export default Footer;
