import React, { useContext, } from "react";
import "./Parcour.scss";
import { ModelContext } from "providers/ModelProvider";
import {
  useParams,
} from "react-router-dom";
import Player from 'shared/Player';
import Parcours from 'components/Parcours';
import Footer from 'components/Footer';
import Menu from 'components/Menu';
import Container from '@mui/material/Container';

function Parcour() {
  const { getDoc } = useContext(ModelContext);
  const { id }=useParams();
  const parcour=getDoc(['parcours',[parseInt(id)]]);
  const footer=<>
  <Container maxWidth="lg" className="parcour">
    <hr />
    <h3>Voir aussi</h3>
    <Parcours mini disableGutters exclude={[parseInt(id)]}/>
  </Container>
  <Footer/>
  </>
  return parcour ? <React.Fragment key={id}>
    <Player parcour={parcour} footer={footer}/>
    <Menu/>
  </React.Fragment> : null;
}

export default Parcour;
