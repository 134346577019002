import React from "react";
import "./Menu.scss";
import {
  useNavigate
} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Fab from '@mui/material/Fab';

function Menu() {
  const navigate=useNavigate();
  return <div className="menu">
      <Fab color="primary" aria-label="menu" onClick={()=>navigate('/')}>
        <HomeIcon />
      </Fab>
  </div>
}

export default Menu;
