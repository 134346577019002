import React from "react";
import MuiMarkdown from 'mui-markdown';
import "./MarkDown.scss";

function Md({text}) {
  return <div className="markdown-preview">
    <MuiMarkdown>{text}</MuiMarkdown>
  </div>;
}

export default Md;
