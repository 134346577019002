import React, { useState } from "react";
import './App.scss';
import 'shared/styles.scss';
import Splashscreen from 'components/Splashscreen';
import Home from 'components/Home';
import Parcour from 'components/Parcour';
import {
  Routes,
  Route,
} from "react-router-dom";

import unmuteAudio from 'unmute-ios-audio';

unmuteAudio();

function App() {
  const [ introDone,setIntroDone ] = useState(false);
  return introDone ? <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/parcours/:id" element={<Parcour />} />
    </Routes>
    : <Splashscreen onDone={()=>setIntroDone(true)}/>;
}

export default App;
