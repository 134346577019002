import React, { useState, createContext, useEffect } from "react";

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const [ screenSize, setScreenSize ] = useState("xxl");
  const [ appState,setAppState ] = useState({
    mainMenu:{
      show:false,
      contextMenu:[],
    }
  });
  useEffect(()=>{
    const handleOrientation = (m)=>{
      if (m.matches) {
        console.log('portrait');
        setAppState(state=>{return{...state,orientation:'portrait'}});
      } else {
        console.log('landscape');
        setAppState(state=>{return{...state,orientation:'landscape'}});
      }
    }
    window.matchMedia('(orientation: portrait)').addListener(handleOrientation);
    handleOrientation(window.matchMedia('(orientation: portrait)'));
    return ()=>{
      window.matchMedia('(orientation: portrait)').removeListener(handleOrientation);
    }
  },[setAppState]);
  useEffect(()=>{
    const resize=()=>{
      const width=document.body.clientWidth;
      let size="xs";
      if (width>576) size="sm";
      if (width>768) size="md";
      if (width>992) size="lg";
      if (width>1200) size="xl";
      if (width>1600) size="xxl";
      setScreenSize(size);
      ["xs","sm","md","lg","xl","xxl"].forEach((s) => {
        if (s!==size) document.body.classList.remove(s);
      });
      document.body.classList.add(size);
    }
    resize();
    window.addEventListener('resize',resize);
    window.addEventListener('pageshow',resize);
    return ()=>{
      window.removeEventListener('resize',resize);
      window.removeEventListener('pageshow',resize);
    }
  },[setScreenSize]);
  return (
        <SettingsContext.Provider value={{appState,screenSize,setAppState}}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
