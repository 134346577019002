import React, { useEffect,useState,useContext } from "react";
import "./Splashscreen.scss";
import Fond from 'components/Fond';
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";

const getFond=(size='xxl',step=1)=>{
  if (step===1){
    if (size==='xs') return {transition:'none',opacity:0,dx:0,dy:-400,ratio:0.2}
    if (size==='sm') return {transition:'none',opacity:0,dx:0,dy:-100,ratio:0.3}
    if (size==='md') return {transition:'none',opacity:0,dx:0,dy:-150,ratio:0.4}
    if (size==='lg') return {transition:'none',opacity:0,dx:0,dy:0,ratio:0.7}
    if (size==='xl') return {transition:'none',opacity:0,dx:-150,dy:200,ratio:1.2}
    if (size==='xxl') return {transition:'none',opacity:0,dx:-150,dy:300,ratio:1.2}
  }
  if (step===2){
    if (size==='xs') return {transition:'all 2s',opacity:1,dx:0,dy:-400,ratio:0.2}
    if (size==='sm') return {transition:'all 2s',opacity:1,dx:0,dy:-100,ratio:0.3}
    if (size==='md') return {transition:'all 2s',opacity:1,dx:0,dy:-150,ratio:0.4}
    if (size==='lg') return {transition:'all 2s',opacity:1,dx:0,dy:0,ratio:0.7}
    if (size==='xl') return {transition:'all 2s',opacity:1,dx:-150,dy:200,ratio:1.2}
    if (size==='xxl') return {transition:'all 2s',opacity:1,dx:-150,dy:300,ratio:1.2}
  }
  if (step===3){
    if (size==='xs') return {transition:'all 2s',opacity:1,dx:50,dy:-1300,ratio:0.5}
    if (size==='sm') return {transition:'all 2s',opacity:1,dx:0,dy:-500,ratio:0.7}
    if (size==='md') return {transition:'all 2s',opacity:1,dx:-50,dy:-450,ratio:0.8}
    if (size==='lg') return {transition:'all 2s',opacity:1,dx:-100,dy:-50,ratio:1.2}
    if (size==='xl') return {transition:'all 2s',opacity:1,dx:-50,dy:200,ratio:1.8}
    if (size==='xxl') return {transition:'all 2s',opacity:1,dx:-50,dy:300,ratio:2}
  }
  if (step===4){
    if (size==='xs') return {transition:'all 0.5s',opacity:0,dx:50,dy:-1300,ratio:0.5}
    if (size==='sm') return {transition:'all 0.5s',opacity:0,dx:0,dy:-500,ratio:0.7}
    if (size==='md') return {transition:'all 0.5s',opacity:0,dx:-50,dy:-450,ratio:0.8}
    if (size==='lg') return {transition:'all 0.5s',opacity:0,dx:-100,dy:-50,ratio:1.2}
    if (size==='xl') return {transition:'all 0.5s',opacity:0,dx:-50,dy:200,ratio:1.8}
    if (size==='xxl') return {transition:'all 0.5s',opacity:0,dx:-50,dy:300,ratio:2}
  }
}

function Splashscreen({onDone}) {
  const { screenSize } = useContext(SettingsContext);
  const [ fond,setFond ] = useState(null);
  const [ on,setOn ] = useState(false);
  const [ off,setOff ] = useState(false);
  const { getDoc } = useContext(ModelContext);
  const settings=getDoc(['settings']) || [];
  const titre=(settings.find((o)=>o.id==='titre') || {value:''}).value;
  useEffect(()=>{
    if (screenSize) {
      const t1=setTimeout(()=>setFond(getFond(screenSize,2)),200);
      const t2=setTimeout(()=>{
        setFond(getFond(screenSize,3));
        setOn(true);
      },1500);
      const t3=setTimeout(()=>{
        setFond(getFond(screenSize,4));
        setOff(true)
      },4500);
      const t4=setTimeout(onDone,5000);
      return ()=>{
        clearTimeout(t1);
        clearTimeout(t2);
        clearTimeout(t3);
        clearTimeout(t4);
      }
    }
  },[onDone,setFond,screenSize]);
  useEffect(()=>{
    setOn(false);
    setFond(getFond(screenSize,1));
  },[onDone,setFond,screenSize,setOn]);
  useEffect(()=>{
    const handleKeyDown=(e)=>{
      if (e.keyCode===83) {
        onDone();
      }
    }
    document.addEventListener('keydown',handleKeyDown);
    return ()=>{
      document.removeEventListener('keydown',handleKeyDown);
    }
  },[onDone]);
  return <>
  <Fond fond={fond}/>
  <div className={"board"+(on?' on':'')+(off?' off':'')}>
    <div className="pirena">
      Pirèna Immatèria
    </div>
    <div className="collectages">
      {titre}
    </div>
  </div>
  </>;
}

export default Splashscreen;
