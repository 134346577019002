import React, { useState,useEffect,useRef,useLayoutEffect } from "react";
import "./Blocs.scss";
import Conf from 'Conf';
import Md from 'shared/Md';
import ReactPlayer from 'react-player';

function Video({bloc,container,params,onPlay,currentPlayer}) {
  const first=useRef(true);
  const [ playing, setPlaying ] = useState(false);
  const [ active,setActive ] = useState(false);
  const cache=useRef(null);
  const player=useRef(null);
  const topMark=useRef(null);
  const bottomMark=useRef(null);
  const [ stickyState, setStickyState ] = useState({
    top:false,
    bottom:false,
  });
  useEffect(()=>{
    const { top,bottom } = stickyState;
    if (top && bottom) {
      if (!active) setActive(true);
      if (first.current) {
        setPlaying(true);
      }
    } else {
      if (active) setActive(false);
    }
  },[stickyState,active,setActive]);
  useEffect(()=>{
    if(currentPlayer && cache.current!==currentPlayer.id) {
      cache.current=currentPlayer.id;
      if (currentPlayer.id!==player.current.id) {
        setPlaying(false);
      }
    }
  },[currentPlayer]);
  useEffect(()=>{
    if (playing) onPlay(player.current);
  },[playing,onPlay]);
  useLayoutEffect(()=>{
    if (container && player.current && topMark.current && bottomMark.current) {
      console.log('init observer');
      const observerTop = new IntersectionObserver(([node], observer)=>{
        const targetInfo = node.boundingClientRect;
        const rootBoundsInfo = node.rootBounds;
        if (targetInfo.bottom < rootBoundsInfo.top) {
          setStickyState(state=>{return{...state,top:true}});
        } else {
          setStickyState(state=>{return{...state,top:false}});
        }
      }, {
        root:container,
        rootMargin: '0px',
        threshold:1,
      });
      observerTop.observe(topMark.current);
      const observerBottom = new IntersectionObserver(([node], observer)=>{
        const targetInfo = node.boundingClientRect;
        const rootBoundsInfo = node.rootBounds;
        if (targetInfo.top < rootBoundsInfo.top) {
          setStickyState(state=>{return{...state,bottom:false}});
        } else {
          setStickyState(state=>{return{...state,bottom:true}});
        }
      }, {
        root:container,
        rootMargin: '0px',
        threshold:1,
      });
      observerBottom.observe(bottomMark.current);
      return ()=>{
        observerTop.disconnect();
        observerBottom.disconnect();
      }
    }
  },[container]);
  return <div className='bloc bloc-video'>
    <div ref={topMark}></div>
    <div className={'player'+(active ? ' active' : '')} ref={player} id={'bloc-'+bloc.id}>
      <div className="video">
        <ReactPlayer playing={playing} onError={(e)=>console.log(e)} onPlay={()=>{setPlaying(true);first.current=false;console.log('playing');}} onPause={()=>setPlaying(false)} url={Conf.filesUrl+bloc.media[0].url} controls width="100%" height="100%"/>
      </div>
      {bloc.metadata && <div className="metadata">
        <Md text={bloc.metadata} />
      </div>}
    </div>
    <div className="spacer"></div>
    {bloc.titre && <div className="titre">
      {bloc.titre}
    </div>}
    {bloc.texte && <div className="texte">
      <Md text={bloc.texte.replace(/\[image:(.*)\]/gm,'![image]('+Conf.filesUrl+'$1-1000)')} />
    </div>}
    <div ref={bottomMark} className="bottom-mark"></div>
    <div className="spacer"></div>
  </div>;
}

export default Video;
