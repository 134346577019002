import React, {useRef} from "react";
import "./Blocs.scss";
import Conf from 'Conf';
import Md from 'shared/Md';

function Texte({bloc}) {
  const bottomMark=useRef(null);
  return <div className='bloc-texte'>
    {bloc.media.filter(o=>o.type.startsWith('image/')) ? <img className="header-image" alt={bloc.titre} src={Conf.filesUrl+bloc.media[0].url+'-1000'}/> : ''}
    <div className="spacer"></div>
    {bloc.titre && <div className="titre">
      {bloc.titre}
    </div>}
    {bloc.texte && <div className="texte">
      <Md text={bloc.texte.replace(/\[image:(.*)\]/gm,'![image]('+Conf.filesUrl+'$1-1000)')} />
    </div>}
    <div ref={bottomMark} className="bottom-mark"></div>
    <div className="spacer"></div>
  </div>;
}

export default Texte;
